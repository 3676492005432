<template>
  <VRow column>
    <VCol style="flex: 0 0 64px">
      <IndexHeader
        mode="standalone"
        :can-create="true"
      />
    </VCol>
    <VCol
      mb-2
      shrink
    >
      <Stats
        :stats="config.stats"
        :options="statOptions"
      />
    </VCol>
    <VCol style="flex: 1 1 auto">
      <DataViewUI
        :data="data"
        :headers="headers"
        :is-loading="activity.isLoading"
        @paginate="onGetMore"
        @search="onSearch"
      >
        <template
          slot="items-cells"
          slot-scope="props"
        >
          <template v-if="$isUserAdmin">
            <td>{{ props.item.calendar.title }}</td>
            <td>
              <VChip
                label
                small
              >
                {{ props.item.calendar.calendarFor }}
              </VChip>
              {{ props.item.calendar.entity.title }}
            </td>
            <td>{{ getEntryDate(props.item, 'date') || '(not set)' }}</td>
            <td>{{ props.item.attendance_submitted ? $timezone.formatDateTime(props.item.attendance_submitted.submitted_on) : '(not set)' }}</td>
            <td>{{ props.item.attendance_submitted ? (props.item.attendance_submitted.submitted_by || {}).name : '(not set)' }}</td>
          </template>
          <template v-else-if="$isUserMentor">
            <td>{{ props.item.calendar.title }}</td>
            <td>{{ props.item.calendar.calendarFor+': '+props.item.calendar.entity.title }}</td>
            <td>{{ getEntryDate(props.item, 'date') || '(not set)' }}</td>
            <td>{{ props.item.attendance_submitted ? $timezone.formatDateTime(props.item.attendance_submitted.submitted_on) : '(not set)' }}</td>
          </template>
          <template v-else>
            <td>{{ props.item.calendar_entry.entity.title }}</td>
            <td>{{ getEntryDate(props.item, 'date') || '(not set)' }}</td>
            <td>{{ props.item.submitted instanceof Object ? $timezone.formatDateTime(props.item.submitted.created_at) : '(not set)' }}</td>
            <td>{{ props.item.submitted.is_present ? "Yes" : 'No' }}</td>
          </template>
          <td class="actions">
            <Menu :disabled="getIsDisabled(props.item)">
              <VListItem
                v-if="!$isUserStudent"
                :href="getHrefForItem(`${modelTypeRoute}.update`, props.item.id)"
                @click.prevent="onSetFormDialogModel(props.item)"
              >
                <VListItemTitle>
                  <VIcon small>
                    edit
                  </VIcon>&nbsp;Edit
                </VListItemTitle>
              </VListItem>
              <VListItem
                :href="getHrefForItem(`${modelTypeRoute}.view`, props.item.id)"
                @click.prevent="onSetViewDialogModel(props.item)"
              >
                <VListItemTitle>
                  <VIcon small>
                    visibility
                  </VIcon>&nbsp;View
                </VListItemTitle>
              </VListItem>
              <VListItem
                v-if="$isUserAdmin"
                @click.prevent="onDeleteItem(props.item)"
              >
                <VListItemTitle color="error">
                  <VIcon
                    small
                    color="error"
                  >
                    delete
                  </VIcon>&nbsp;Delete
                </VListItemTitle>
              </VListItem>
            </Menu>
          </td>
        </template>
      </DataViewUI>
      <FormDialog
        :current-model="currentModel"
        :is-dialog-open="dialogs.form"
        :title="currentModel instanceof Object ? currentModel.title : ''"
        @toggle:form:dialog="onToggleFormDialog"
        @deleteItem="onDeleteItem"
        :model-type="modelType"
      >
        <Save
          v-if="!$isUserStudent"
          ref="saveForm"
          mode="dialog"
          :id="currentModel ? currentModel.id : null"
        />
        <Submit
          v-else
          ref="saveForm"
          mode="dialog"
          :model-type="modelType"
          :attendance="currentModel"
          :id="currentModel ? currentModel.submitted.id : null"
        />
      </FormDialog>
      <Confirm ref="confirmDelete" />
      <Dialog
        max-width="640px"
        :is-dialog-open="dialogs.view"
        :title="`View ${featureName('Attendance')}`"
        @toggle:dialog="onToggleViewDialog"
      >
        <ViewComponent
          :id="currentModel ? currentModel.id : null"
          ref="viewItem"
          mode="dialog"
        />
      </Dialog>
    </VCol>
  </VRow>
</template>

<script>
import SearchMixin from "@/mixins/Search";
import HasStats from "@/mixins/HasStats";
import Save from "./Save";
import Submit from "./Submit";
import ViewComponent from "./View";
import { mapActions } from "vuex";
import IndexHeader from "@/components/Elements/Navigation/IndexHeader";
export default {
  name: "AttendanceIndex",
  components: {
    Save,
    Submit,
    ViewComponent,
    IndexHeader,
  },
  mixins: [SearchMixin, HasStats],
  data() {
    return {
      date: null,
      modelType: "attendance",
      config: {
        stats: {
          "-": "-",
        },
      },
      statOptions: {
        inflect: {
          submitted: {
            singular: "Submitted",
            plural: "Submitted",
          },
        },
      },
    };
  },
  computed: {
    headers() {
      let headers;
      if (this.$isUserAdmin) {
        headers = [
          {
            text: "Calendar",
            align: "left",
            sortable: true,
            value: "calendar.title",
          },
          {
            text: "For",
            align: "left",
            sortable: false,
            value: "calendar_entry.entity.title",
          },
          {
            text: "Held On",
            align: "left",
            sortable: true,
            value: "calendar_entry.date",
          },
          {
            text: "Submitted On",
            align: "left",
            sortable: true,
            value: "attendance_submitted.submitted_on",
          },
          {
            text: "Submitted By",
            align: "left",
            sortable: true,
            value: "attendance_submitted.submitted_by.name",
          },
        ];
      } else if (this.$isUserMentor) {
        headers = [
          {
            text: "Calendar",
            align: "left",
            sortable: true,
            value: "calendar.title",
          },
          {
            text: "For",
            align: "left",
            sortable: false,
            value: "calendar_entry.entity.title",
          },
          {
            text: "Held On",
            align: "left",
            sortable: true,
            value: "calendar_entry.date",
          },
          {
            text: "Submitted On",
            align: "left",
            sortable: true,
            value: "attendance_submitted.submitted_on",
          },
        ];
      } else {
        headers = [
          {
            text: this.featureName("Program"),
            align: "left",
            sortable: true,
            value: "calendar_entry.entity.title",
          },
          {
            text: "Held On",
            align: "left",
            sortable: true,
            value: "calendar_entry.date",
          },
          {
            text: "Submitted On",
            align: "left",
            sortable: true,
            value: "created_at",
          },
          {
            text: "Present",
            align: "left",
            sortable: true,
            value: "is_present",
          },
        ];
      }
      return headers;
    },
  },
  created() {
    this.doGetIndexConfig().then((result) => (this.config = result));
  },
  methods: {
    ...mapActions({
      doGetIndexConfig: "attendance/getIndexConfig",
      doGetAttendances: "attendance/getAll",
      doGetAttendance: "attendance/getOne",
      doDeleteAttendance: "attendance/delete",
      doGetSubmitted: "attendanceSubmitted/getAll",
    }),
    onDelete(attendance) {
      return this.doDeleteAttendance(attendance.id);
    },
    onGetAll(params) {
      return this.doGetAttendances(params);
    },
    onGet(id) {
      return this.doGetAttendance(id);
    },
    getEntryDate(attendance, property, noTime) {
      const date = attendance.calendar_entry
        ? attendance.calendar_entry[property]
        : null;
      return date
        ? this.$timezone.formatDate(date) +
            (noTime ? "" : " @ " + this.$timezone.formatTime(date))
        : null;
    },
  },
};
</script>